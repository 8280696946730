<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <template #toolbar>
        <dsb-products-sync
          v-if="hadAdminRole"
          @sync:success="$_refreshData"
        />
      </template>

      <vbt-table
        :headers="productsHeaders"
        :items="products"
      >
        <template #enabled="{ item }">
          <dsb-state-icon :state="item.enabled" />
        </template>

        <template #printable="{ item }">
          <dsb-state-icon :state="item.printable" />
        </template>

        <template #multipage="{ item }">
          <dsb-product-multipage-state
            v-if="hadAdminRole"
            :name="item.name"
            :state="item.multipage"
            @multipage:state:change:success="$_fetchData"
          />

          <dsb-entity-badge
            v-else
            :color="item.multipage ? 'success' : 'error'"
          >
            {{ item.multipage ? 'Enabled' : 'Disabled' }}
          </dsb-entity-badge>
        </template>

        <template #actions="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                small
                color="info"
                v-on="on"
                @click="showProductHistory(item.name)"
              >
                <v-icon>
                  book
                </v-icon>
              </v-btn>
            </template>
            Show history
          </v-tooltip>
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
        :total-count="productsTotalCount"
      />
    </vbt-content-box>

    <vbt-dialog
      v-model="productHistoryDialog"
      :title="productHistoryDialogTitle"
      hide-submit-btn
    >
      <vbt-table
        :headers="productHistoryHeaders"
        :items="productHistory"
      >
        <template #processed="{ item: { processed: _dateForFormatting } }">
          {{ formatDisplayDate(_dateForFormatting) }}
        </template>

        <template #targetMultipage="{ item }">
          <dsb-state-icon :state="item.targetMultipage" />
        </template>
      </vbt-table>
    </vbt-dialog>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import {
  AuthNamespace,
  AutocompleteNamespace,
  AutocompleteActionTypes,
  AppLocationsNamespace,
} from '@store/types';

import {
  namespace as ProductsNamespace,
  ActionTypes as ProductsActionTypes,
} from '../store.types';

import DsbProductsSync from '../_components/DsbProductsSync.vue';
import DsbProductMultipageState from '../_components/DsbProductMultipageState.vue';

const dataLoadingOptions = {
  getterName: ProductsActionTypes.GET_PRODUCTS,
  contentName: 'products',
  specialQueryParams: [
    {
      name: 'multipage',
      type: 'custom',
      parser: (value) => {
        const types = { yes: true, no: false };

        return value ? types[value.toLowerCase()] : null;
      },
    },
  ],
};
const paginationOptions = { contentName: 'products' };
const searchOptions = {
  initialSearchDataFields: [
    'name', 'multipage',
  ],
};

const productsHeaders = Object.freeze([
  { text: 'Name', value: 'name' },
  { text: 'Abbreviation', value: 'abbreviation' },
  { text: 'Enabled', value: 'enabled' },
  { text: 'Printable', value: 'printable' },
  { text: 'Multipage', value: 'multipage' },
  { text: '', value: 'actions', width: '50px' },
]);

const productHistoryHeaders = Object.freeze([
  { text: 'Operator', value: 'operator' },
  { text: 'Processed Date', value: 'processed' },
  { text: 'Multipage', value: 'targetMultipage' },
]);

export default {
  name: 'DsbProducts',

  components: {
    DsbProductsSync,
    DsbProductMultipageState,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      productHistoryDialog: false,
      productHistoryDialogTitle: '',

      productsHeaders,
      productHistoryHeaders,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
    ...mapState(AutocompleteNamespace, ['productAutocomplete']),

    ...mapState(ProductsNamespace, ['products', 'productsTotalCount', 'productHistory']),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),

    searchFieldsConfig() {
      return [
        {
          value: 'name',
          label: 'Name',
          type: 'autocomplete',
          placeholder: 'Start typing...',
          items: this.productAutocomplete,
          callback: this.getProductAutocomplete,
          flex: 'sm4',
        },
        {
          value: 'multipage',
          label: 'Multipage',
          items: ['Yes', 'No'],
          type: 'select',
          flex: 'sm2',
        },
      ];
    },
  },

  methods: {
    ...mapActions(AutocompleteNamespace, {
      getProductAutocomplete: (dispatch, query) => dispatch(
        AutocompleteActionTypes.GET_PRODUCT_AUTOCOMPLETE,
        { query },
      ),
    }),

    ...mapActions(ProductsNamespace, [ProductsActionTypes.GET_PRODUCTS]),
    ...mapActions(ProductsNamespace, {
      showProductHistory(dispatch, name) {
        this.wrapToLoadingFn({
          req: dispatch.bind({}, ProductsActionTypes.GET_PRODUCT_HISTORY, {
            suffix: this.suffix,
            name,
          }),
          fullscreenLoadingSpinner: true,
          onSuccess: () => {
            if (this.productHistory.length) {
              this.productHistoryDialogTitle = `"${name}" history`;
              this.productHistoryDialog = true;
            } else {
              this.$VBlackerTheme.notification.warning('Empty history');
            }
          },
        });
      },
    }),

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>
