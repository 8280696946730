<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <span v-on="on">
        <v-btn
          color="success"
          small
          icon
          @click="sync"
        >
          <v-icon>
            sync
          </v-icon>
        </v-btn>
      </span>
    </template>
    Sync Products
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { ProductsApiService } from '../_services/products.api.service';

export default {
  name: 'DsbProductsSync',

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
  },

  methods: {
    sync() {
      this.$VBlackerTheme.alert.warning({
        text: 'Sync products?',
      }, () => this.wrapToLoadingFn({
        req: ProductsApiService.syncProducts.bind({}, {
          suffix: this.suffix,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully synced');
          this.$emit('sync:success');
        },
      }));
    },

    wrapToLoadingFn,
  },
};
</script>
